<template>
<nav class="navbar navbar-expand-lg shadow bg-primary navbar-dark">
  <div class="container">
    <router-link class="navbar-brand" href="/docs"><img src="@/assets/log.png" class="me-2" style="height: 30px;">Bontang</router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link class="nav-link active" aria-current="page" to="/docs">Home</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" aria-current="page" to="/data">Data</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" aria-current="page" to="/users">Penguna</router-link>
        </li>
      </ul>
    </div>
  </div>
</nav>
</template>