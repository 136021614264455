<template>
    <NavbarMain/>
  <div class="container pt-5">
    <!-- Form Register -->
    <div class="card bg-light mb-3">
      <div class="card-header">Register</div>
      <div class="card-body">
        <form @submit.prevent="registerUser">
          <div class="mb-3">
            <label for="registerName" class="form-label">Name</label>
            <input type="text" v-model="registerData.name" class="form-control" id="registerName" required>
          </div>
          <div class="mb-3">
            <label for="registerEmail" class="form-label">Email address</label>
            <input type="email" v-model="registerData.email" class="form-control" id="registerEmail" required>
          </div>
          <div class="mb-3">
            <label for="registerPassword" class="form-label">Password</label>
            <input type="password" v-model="registerData.password" class="form-control" id="registerPassword" required>
          </div>
          <div class="mb-3">
            <label for="registerRole" class="form-label">Role</label>
            <input type="text" v-model="registerData.role" class="form-control" id="registerRole" required>
          </div>
          <button type="submit" class="btn btn-primary">Register</button>
        </form>
      </div>
    </div>
    
    <!-- Tabel Pengguna -->
    <div class="card bg-light">
      <div class="card-header">User Management</div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users" :key="user.id">
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td>{{ user.role }}</td>
                <td>
                  <button class="btn btn-danger" @click="deleteUser(user.id)">Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import NavbarMain from '@/components/NavbarMain.vue'

export default {
  data() {
    return {
      registerData: {
        name: '',
        email: '',
        password: '',
        role: ''
      },
      users: []
    };
  },
  components: {
    NavbarMain
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    async registerUser() {
      try {
        await axios.post(process.env.VUE_APP_API_URL + 'register', this.registerData);
        alert('Registration successful');
        this.registerData = { name: '', email: '', password: '', role: '' };
        this.fetchUsers(); // Refresh daftar pengguna setelah registrasi
      } catch (error) {
        alert('Registration failed');
        console.error("Error registering user:", error);
      }
    },
    async fetchUsers() {
      try {
        const response = await axios.get(process.env.VUE_APP_API_URL + 'users');
        this.users = response.data;
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    },
    async deleteUser(id) {
      if (confirm('Are you sure you want to delete this user?')) {
        try {
          await axios.delete(`http://localhost:8000/api/users/${id}`);
          this.users = this.users.filter(user => user.id !== id);
          alert('User deleted successfully');
        } catch (error) {
          console.error("Error deleting user:", error);
          alert('Failed to delete user');
        }
      }
    }
  }
};
</script>

<style scoped>
.card {
  margin-bottom: 20px;
}
</style>
