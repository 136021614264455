<template>
    <div class="bg-light d-flex justify-content-center align-items-center" style="height: 100vh;">
      <div class="card bg-white rounded shadow w-50 p-5">
        <div class="row">
          <div class="col">
            <img class="w-100" src="@/assets/logo26.png" alt="">
          </div>
          <div class="col">
            <h1>Login.</h1>
            <form @submit.prevent="submitLogin">
              <div class="mb-3">
                <label for="email" class="form-label">Email address</label>
                <input type="email" class="form-control" id="email" v-model="email" required>
                <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Password</label>
                <input type="password" class="form-control" id="password" v-model="password" required>
              </div>
              <button type="submit" class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        email: '',
        password: ''
      };
    },
    methods: {
      async submitLogin() {
        try {
          const response = await axios.post(process.env.VUE_APP_API_URL + 'login', {
            email: this.email,
            password: this.password
          });
  
          if (response.status === 200) {
            // Simpan token atau informasi pengguna
            localStorage.setItem('user', JSON.stringify(response.data));
            alert('Login berhasil');
            // Redirect ke halaman dashboard atau lainnya
            this.$router.push('/docs');
          }
        } catch (error) {
          alert('Login gagal. Periksa kembali email dan password Anda.');
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* Tambahkan style khusus jika diperlukan */
  </style>
  