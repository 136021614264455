<template>
  <div class="home" style="width: 100%; overflow-x: hidden;">
    <div style="text-align: center;">
      <img src="@/assets/logo26.png" class="mt-3 mb-3" width="220px">
    </div>
    <hr>
    <div class="container gg mb-5 pb-3 mt-5">
      <div class="card col-10 mx-auto shadow">
        <div class="card-header text-center">
          <h4>Cek Tagihan Pelanggan</h4>
        </div>
        <div class="card-body">
          <div class="container-fluid h-100">
            <div class="row justify-content-center align-items-center h-100">
              <div class="input-group rs mb-3">
                <form id="myForm" @submit.prevent="validateForm" style="width: 100%;">
                  <div class="input-group" style="width: 100%;">
                    <input type="number" class="form-control text-center" id="no_pelanggan" name="no_pelanggan" placeholder="Masukkan ID Pelanggan" autocomplete="off">
                    <div class="input-group-append">
                      <button class="btn btn-primary ms-3" type="submit">Cek</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <table v-if="isData" class="table table-striped">
              <thead>
                <tr></tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nomor Pelanggan</td>
                  <td>{{ data[0].nopel }}</td>
                </tr>
                <tr>
                  <td>Nama</td>
                  <td>{{ data[0].nama }}</td>
                </tr>
                <tr>
                  <td>Alamat</td>
                  <td>{{ data[0].alamat }}</td>
                </tr>
                <tr>
                  <td>Golongan</td>
                  <td>{{ data[0].gol_tarif }}</td>
                </tr>
                <tr>
                  <td>Jumlah Tagihan</td>
                  <td>{{ data.length }} Periode</td>
                </tr>
                <tr>
                  <td>Biaya Admin</td>
                  <td>Rp 3.000,00</td>
                </tr>
                <tr>
                  <td class="fw-bold">Total Tagihan</td>
                  <td class="fw-bold">{{ formatRupiah(total) }}</td>
                </tr>
                <tr>
                  <td colspan="2" class="bg-white">
                    <p class="text-center text-info">Detail Transaksi</p>
                    <div class="card shadow border-0 mt-2 p-2" v-for="item in data" :key="item.id">
                      <div class="row">
                        <div style="font-size: 13px;" class="col-3">Periode</div>
                        <div style="font-size: 13px;" class="col">{{ item.periode }}</div>
                      </div>
                      <div class="row mt-1">
                        <div style="font-size: 13px;" class="col-3">Pemakaian</div>
                        <div style="font-size: 13px;" class="col">{{ item.pakai }} m<sup>3</sup></div>
                      </div>
                      <div class="row mt-1">
                        <div style="font-size: 13px;" class="col-3">Tagihan</div>
                        <div style="font-size: 13px;" class="col">{{ formatRupiah(item.tagihan_air) }}</div>
                      </div>
                      <div class="row mt-1">
                        <div style="font-size: 13px;" class="col-3">Denda</div>
                        <div style="font-size: 13px;" class="col">{{ formatRupiah(item.denda) }}</div>
                      </div>
                      <div class="row mt-1">
                        <div style="font-size: 13px;" class="col-3 fw-bold">Total</div>
                        <div style="font-size: 13px;" class="col fw-bold">{{ formatRupiah(item.total) }}</div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <h3 v-if="isPaid" class="text-center my-3">Terima kasih atas pembayaran anda </h3>
                    <div class="d-flex justify-content-center">
                      <button v-if="!isPaid" class="btn btn-primary me-2" @click="payment">Bayar Tagihan</button>
                      <button v-if="isPaid" class="btn btn-success me-2" @click="downloadReceipt">Download Bukti Pembayaran</button>
                      <!-- <button class="btn btn-success me-2" @click="downloadReceipt">Download Bukti Pembayaran</button> -->
                      <button class="btn btn-secondary" @click="close">Tutup</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="load" style="display: none;">
      <div class="spinner-border text-light" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <InfoBanner/>
    <CopyRight/>
  </div>
</template>

<script>
import CopyRight from "@/components/CopyRight";
import InfoBanner from "@/components/InfoBanner";
import axios from "axios";
import Swal from "sweetalert2";
import jsPDF from "jspdf"; // Import jsPDF
import 'jspdf-autotable';

export default {
  name: 'HomeView',
  components: {
    CopyRight,
    InfoBanner
  },
  data() {
    return {
      isData: false,
      data: [],
      total: 0,
      nama: "", 
      nopel: "", 
      periode: "",
      periode2: "",
      isPaid: false, // Status pembayaran
      receiptData: null // Data bukti pembayaran
    }
  },
  methods: {
    payment() {
      if (!this.periode) {
        Swal.fire({
          title: "Gagal!",
          text: "Periode tidak ditemukan. Silahkan cek tagihan kembali.",
          icon: "error"
        });
        return;
      }
      axios.post(process.env.VUE_APP_API_URL + 'midtrans', {
        total: this.total,
        nama: this.nama
      })
      .then(response => {
        let no_pelanggan = document.getElementById("no_pelanggan").value;
        let snapToken = response.data.token;
        window.snap.pay(snapToken, {
          onSuccess: () => {
            document.querySelector(".load").style.display = "flex";
            if(this.periode2 != ""){
              axios.get(`${process.env.VUE_APP_API_URL}pelunasan/${no_pelanggan}/${this.periode2}`)
            }
            axios.get(`${process.env.VUE_APP_API_URL}pelunasan/${no_pelanggan}/${this.periode}`)
              .then(response => {
                axios.post(`${process.env.VUE_APP_API_URL}transactions`, {
                  nama: this.nama,
                  alamat: "-",
                  no_pelanggan: this.nopel,
                  no_bukti: this.formatDate(new Date()),
                  periode: this.periode + " " + this.periode2,
                  golongan_tarif: "-",
                  angsuran: "-",
                  angka_lalu: "-",
                  angka_kini: "-",
                  pakai: "-",
                  tagihan_air: "-",
                  denda: "-",
                  biaya_tutup: "-",
                  retribusi: "-",
                  total: `${this.total}`
                })
                  .then(() => {
                    console.log("berhasil");
                  })
                  .catch(error => {
                    console.log(error);
                  })

                document.querySelector(".load").style.display = "none";
                Swal.fire({
                  title: "Berhasil",
                  text: "Pembayaran Selesai",
                  icon: "success"
                });
                this.isPaid = true; // Setel pembayaran berhasil
                this.receiptData = response.data; // Simpan data bukti pembayaran
              })
              .catch(error => {
                console.error(error);
                document.querySelector(".load").style.display = "none";
                Swal.fire({
                  title: "Gagal!",
                  text: "Terjadi kesalahan saat mengonfirmasi pelunasan",
                  icon: "error"
                });
              });
          },
          onError: () => {
            Swal.fire({
              title: "Gagal!",
              text: "Server sedang bermasalah",
              icon: "error"
            });
          }
        });
      })
      .catch(error => {
        console.error(error.response.data);
        Swal.fire({
          title: "Gagal!",
          text: "Server sedang bermasalah",
          icon: "error"
        });
      });
    },
    formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },
    validateForm() {
      let no_pelanggan = document.getElementById("no_pelanggan").value;
      if (no_pelanggan === "") {
        Swal.fire({
          title: "Failed!",
          text: "ID Pelanggan tidak boleh kosong",
          icon: "error"
        });
      } else {
        axios.get(`${process.env.VUE_APP_API_URL}tunggakan/${no_pelanggan}`)
          .then(response => {
            console.log(response.data);
            if (response.data.length === 0) {
              const { startDate, endDate } = this.getStartAndEndDateOfMonth();
              axios.get(`${process.env.VUE_APP_API_URL}data-pelunasan/${startDate}/${endDate}`)
                .then(response => {
                  let cek = false;
                  response.data.forEach(item => {
                    if (item.nopel == no_pelanggan) {
                      cek = true;
                    }
                  });

                  if (cek) {
                    Swal.fire({
                      title: "Gagal!",
                      text: "Tagihan Telah Dibayarkan",
                      icon: "error"
                    });
                  } else {
                    Swal.fire({
                      title: "Gagal!",
                      text: "Nomer Pelanggan Tidak Ditemukan",
                      icon: "error"
                    });
                  }
                })
                .catch(error => {
                  console.error(error);
                  Swal.fire({
                    title: "Gagal!",
                    text: "Terjadi kesalahan saat mengambil data pelunasan",
                    icon: "error"
                  });
                });
            } else if (response.data.length > 2) {
              Swal.fire({
                title: "Tunggakan Lebih dari 2 Periode!",
                text: "Untuk Melakukan Pembayaran Silahkan Menghubungi Kantor",
                icon: "error"
              });
            } else {
                this.isPaid = false; // Setel pembayaran berhasil
                this.receiptData = null; // Simpan data bukti pembayaran
              this.data = response.data;
              this.isData = true;
              this.total = response.data.length == 1? parseInt(response.data[0].total) + 3000 : parseInt(response.data[0].total) + parseInt(response.data[1].total) + 3000;
              this.nama = response.data[0].nama;
              this.nopel = response.data[0].nopel;
              if(response.data.length == 1){
                this.periode = response.data[0].periode;
                this.periode2 = "";
              }else {
                this.periode = response.data[0].periode;
                this.periode2 = response.data[1].periode;
              }
            }
          })
          .catch(error => {
            console.error(error.response.data);
            Swal.fire({
              title: "Gagal!",
              text: "Nomer Pelanggan Tidak Ditemukan",
              icon: "error"
            });
          });
      }
    },
    formatRupiah(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR'
      }).format(value);
    },
    downloadReceipt() {
    const doc = new jsPDF();
    const date = new Date().toLocaleDateString('id-ID');
    const adminFee = 3000;
    const topMargin = 10; // Margin atas 10px

    // Set Font Bold dan Ukuran Font 18 untuk Judul
    doc.setFontSize(18);
    doc.setFont("helvetica", "bold"); // Menggunakan font Helvetica dengan gaya tebal
    doc.text('Bukti Pembayaran PDAM Kota Bontang', 14, topMargin + 5); // 10px margin + 5px offset

    // Kembali ke Ukuran Font Normal dan Gaya Regular
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal"); // Kembali ke gaya font regular

    // Informasi Pembayaran
    const pembayaranData = [
      ['Nomor Pelanggan', this.data[0].nopel],
      ['Nama', this.data[0].nama],
      ['Alamat', this.data[0].alamat],
      ['Golongan', this.data[0].gol_tarif],
      ['Jumlah Tagihan', `${this.data.length} Periode`],
      ['Biaya Admin', 'Rp 3.000,00'],
      ['Tanggal Pembayaran', date]
    ];

    // Tabel Informasi Pembayaran
    doc.autoTable({
      startY: topMargin + 15,
      theme: 'striped',
      head: [['Keterangan', '']],
      body: pembayaranData,
      styles: { cellPadding: 2, fontSize: 12 }
    });

    // Detail Transaksi
    const tableData = this.data.map(item => [
      item.periode,
      `${item.pakai} m³`,
      this.formatRupiah(item.tagihan_air),
      this.formatRupiah(item.denda),
      this.formatRupiah(item.total)
    ]);

    // Tabel Detail Transaksi
    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 10, // Posisi tabel detail setelah tabel informasi
      head: [['Periode', 'Pemakaian', 'Tagihan', 'Denda', 'Total']],
      body: tableData,
      theme: 'striped',
      styles: { cellPadding: 2, fontSize: 12 }
    });

      
      doc.setFont("helvetica", "bold");
      // Total Pembayaran
      const totalTagihan = this.data.reduce((acc, item) => acc + parseInt(item.total), 0) + adminFee;
      doc.text(`Total Tagihan: ${this.formatRupiah(totalTagihan)}`, 14, doc.previousAutoTable.finalY + 20);

      // Unduh PDF
      doc.save(`Bukti_Pembayaran_${this.nama}_no_pelangan_${this.nopel}.pdf`);
    },
    close() {
      this.isData = false;
      document.getElementById("no_pelanggan").value = "";
    },
    getStartAndEndDateOfMonth() {
      const date = new Date();
      const startDate = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0];
      const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0];
      return { startDate, endDate };
    }
  }
}
</script>

<style scoped>
/* Tambahkan gaya jika diperlukan */
.load {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-success {
  background-color: #28a745;
  border-color: #28a745;
}
</style>
