<template>
    <NavbarMain/>
    <div class="container pt-5">
        <h1>Response Code</h1>
        <h3 class="fw-light mt-3">HTTP Status Code</h3>
        <br><br>
        <div class="alert alert-primary mb-5" role="alert">
         https://api.perumdatirtataman.com/api
        </div>
        <table class="table mb-5">
            <thead>
                <tr>
                <th scope="col">#</th>
                <th scope="col">Code</th>
                <th scope="col">Description</th>
                <th scope="col">Status</th>
                <th scope="col">Solution</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <th scope="row">1</th>
                <td>200 - OK</td>
                <td>Permintaan berhasil diproses dan respons diberikan.</td>
                <td>Success</td>
                <td>Cek data respons dan gunakan sesuai kebutuhan.</td>
                </tr>
                <tr>
                <th scope="row">2</th>
                <td>201 - Created</td>
                <td>Resource baru berhasil dibuat.</td>
                <td>Success</td>
                <td>Gunakan URL resource baru atau tampilkan pesan sukses.</td>
                </tr>
                <tr>
                <th scope="row">3</th>
                <td>204 - No Content</td>
                <td>Permintaan berhasil diproses, tapi tidak ada konten yang dikembalikan.</td>
                <td>Success</td>
                <td>Tampilkan pesan sukses tanpa memuat data.</td>
                </tr>
                <tr>
                <th scope="row">4</th>
                <td>400 - Bad Request</td>
                <td>Permintaan tidak valid atau parameter salah.</td>
                <td>Failed</td>
                <td>Periksa kembali permintaan atau parameter yang diberikan.</td>
                </tr>
                <tr>
                <th scope="row">5</th>
                <td>401 - Unauthorized</td>
                <td>Otentikasi diperlukan atau gagal.</td>
                <td>Failed</td>
                <td>Pastikan token otentikasi valid dan perbarui jika perlu.</td>
                </tr>
                <tr>
                <th scope="row">6</th>
                <td>403 - Forbidden</td>
                <td>Permintaan valid tapi tidak memiliki hak akses.</td>
                <td>Failed</td>
                <td>Periksa hak akses atau perizinan pengguna.</td>
                </tr>
                <tr>
                <th scope="row">7</th>
                <td>404 - Not Found</td>
                <td>Resource yang diminta tidak ditemukan.</td>
                <td>Failed</td>
                <td>Pastikan URL resource benar dan coba lagi.</td>
                </tr>
                <tr>
                <th scope="row">8</th>
                <td>409 - Conflict</td>
                <td>Terjadi konflik pada permintaan seperti duplikasi data.</td>
                <td>Failed</td>
                <td>Periksa data yang diberikan dan selesaikan konflik.</td>
                </tr>
                <tr>
                <th scope="row">9</th>
                <td>422 - Unprocessable Entity</td>
                <td>Permintaan valid tetapi tidak dapat diproses.</td>
                <td>Failed</td>
                <td>Periksa validasi input atau format data yang diberikan.</td>
                </tr>
                <tr>
                <th scope="row">10</th>
                <td>500 - Internal Server Error</td>
                <td>Terjadi kesalahan di server.</td>
                <td>Failed</td>
                <td>Cek log server atau hubungi administrator sistem.</td>
                </tr>
            </tbody>
            </table>

    </div>
</template>

<script>
import NavbarMain from "@/components/NavbarMain.vue"

export default {
    name: "DocsMain",
    components: {
        NavbarMain
    }
}
</script>

<style scoped>
    body {
        color: black;
    }
</style>