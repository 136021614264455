<template>
    <div>
      <NavbarMain/>
      <div class="container pt-5">
        <h1>Riwayat Transaksi</h1>
        <hr>
        <br>
        <div v-if="loading" class="text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else>
            <div class="table-responsive">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Nomor Pelanggan</th>
                      <th scope="col">Nama</th>
                      <th scope="col">Periode</th>
                      <th scope="col">Tanggal Pelunasan</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in transactions" :key="item.id">
                      <th scope="row">{{ index + 1 }}</th>
                      <td>{{ item.no_pelanggan }}</td>
                      <td>{{ item.nama }}</td>
                      <td>{{ item.alamat }}</td>
                      <td>{{ item.periode }}</td>
                      <td>{{ item.no_bukti }}</td>
                      <td>{{ formatRupiah(item.total) }}</td>
                    </tr>
                  </tbody>
                </table>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import NavbarMain from "@/components/NavbarMain.vue";
  import axios from "axios";
  
  export default {
    name: "DataView",
    components: {
      NavbarMain
    },
    data() {
      return {
        transactions: [],
        loading: true
      };
    },
    methods: {
      fetchTransactions() {
        axios.get(process.env.VUE_APP_API_URL + 'transactions')
          .then(response => {
            this.transactions = response.data;
          })
          .catch(error => {
            console.error("There was an error fetching the transactions!", error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      formatRupiah(value) {
        return `Rp ${value.toLocaleString('id-ID', { minimumFractionDigits: 2 })}`;
      }
    },
    mounted() {
      this.fetchTransactions();
    }
  }
  </script>
  
  <style scoped>
  </style>
  