<template>
    <div class="container gg mb-5 pb-3 mt-5">
            <div class="card col-10 mx-auto shadow">
                <div class="card-header text-center">
                    <h4>Info Loket Pembayaran</h4>
                </div>
                <div class="card-body">                    
                  <div class="container image mt-3">
                      <div class="row mb-3">
                          <div class="col-lg-12">
                              <img src="@/assets/loket.jpg" alt="" style="width: 100%;">
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-lg-6">
                              <h4 class="text-center loket">BANKALTIMTARA</h4>
                              <img src="@/assets/kaltim.jpg" alt="" style="width: 100%;">
                          </div>
                          <div class="col-lg-6">
                              <h4 class="text-center loket">POS INDONESIA</h4>
                              <img src="@/assets/pospay.jpg" alt="" style="width: 100%;">
                          </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
</template>